import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  CreatePassword,
} from "@/components/index"

export default function Confirmation() {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO title="Free Course Opt-in" description="" noindex={true}/>
      <Box
        bg="agate"
        minheight="100%"
        height="100%"
        z
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", null, null, "row"]}
          pt={["0", null, null, "6rem"]}
          pb={["0", null, null, "3.6rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="center"
            mx="auto"
          >
            <Text variant="subhead">Accelerated Learning Course</Text>
            <Heading level="2" mb="2rem">
              Welcome!
            </Heading>
            <Text mb="3.2rem">
              Check your inbox (in about 15 minutes) with instructions on how to
              make sure you get every lesson of the FREE 8 day course!
            </Text>
            <Text>Look for an email with the subject line:</Text>
<Text><strong>"How to get your free 8 day email course"</strong></Text>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
